.favorites-drag-drop-list {
    .card {
      max-height: 100%;
      height: 100%;
      margin-bottom: 0;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Adds a subtle shadow to the whole card for depth
  
      .card-title {
        text-align: center;
        padding: 1rem 0;
      }
  
      .card-body {
        margin: 0;
        margin-top: 1rem;
        height: 320px;
        overflow-y: auto;
        padding-top: 0;
        padding-bottom: 0;
  
        .row {
          background-color: #f8f9fa; // Light gray, similar to .bg-light
          margin-bottom: 1rem;
          border-radius: 5px; // Adds rounded corners for a softer look
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); // Subtle shadow for each row
          transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; // Smooth transition for transform and shadow
  
          cursor: grab; // Indicates the item can be dragged
  
          &:hover, &:focus {
            transform: scale(0.98); // Adjusted scale for a subtle hover effect
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); // Enhanced shadow on hover for depth
            background-color: #e9ecef; // Slightly darker than .bg-light
          }
  
          p {
            margin: 0;
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }