$card-border-radius: 0.25rem;
$card-width-mobile: 18%;
$card-width-tablet: 19.5%;
$card-width-desktop: 17.9%;

.card-base {
  position: absolute;
  z-index: 3;
  border-radius: $card-border-radius;
  transform: translate(-50%, -50%);
}


.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1rem;

  @media (max-width: 767px) {
    /* Móviles */
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Tablets */
    width: 13px;
    height: 13px;
    font-size: 7.5px;
    margin-right: 5px;
  }

  @media (min-width: 1024px) {
    width: 20px;
    height: 20px;
    font-size: 11px;
    margin-right: 5px;
  }
}

.card-title {
  font-size: 1rem;

  @media (max-width: 767px) {
    /* Móviles */
    font-size: 5px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Tablets */
    font-size: 0.5rem;
  }

  @media (min-width: 1024px) {
    /* Escritorios y pantallas más grandes */
    font-size: 1rem;
  }
}


.card-bap,
.card-isave,
.card-dosificacion,
.card-water-feed,
.card-rack-inlet,
.card-rack-outlet,
.card-salmuera,
.card-flushing,
.card-producto {
  @extend .card-base;
  right: 5%;
}

@media (min-width: 1024px) {

  .card-base {
    width: $card-width-desktop;
  }

  .card-bap {
    height: 33%;
    top: 79.8%;
    left: 37.5%;
  }

  .card-isave {
    height: 33%;
    top: 79.8%;
    right: 20.5%;
  }

  .card-flushing {
    height: 33%;
    top: 79.8%;
    right: -5.5%;
  }

  .card-dosificacion {
    height: 35%;
    top: 78.3%;
    right: 51%;
  }

  .card-water-feed {
    height: 33%;
    top: 79.8%;
    right: 66%;
  }

  .card-rack-inlet {
    height: 19.5%;
    top: 8.3%;
    right: 35%;
  }

  .card-rack-outlet {
    height: 19.4%;
    top: 32%;
    right: -4.9%;
  }

  .card-salmuera {
    height: 35%;
    top: 49.5vh;
    right: 5.4vh;
  }



  .card-producto {
    height: 19.4%;
    top: 8.3%;
    right: -4.9%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .card-base {
    width: $card-width-tablet;
  }

  .card-bap {
    height: 33%;
    top: 79.5%;
    right: 42.3%;
  }

  .card-isave {
    height: 33%;
    top: 79.5%;
    right: 17.8%;
  }

  .card-flushing {
    height: 33%;
    top: 79.8%;
    right: -9.5%;
  }

  .card-dosificacion {
    top: 85vh;
    right: 50vw;
  }

  .card-water-feed {
    height: 33%;
    top: 79.5%;
    right: 64.5%;
  }

  .card-rack-inlet {
    height: 23%;
    top: 7%;
    right: 30%;
  }

  .card-rack-outlet {
    height: 18%;
    top: 31%;
    right: -6.0%;
  }


  .card-producto {
    height: 23%;
    top: 7%;
    right: -5.5%;
  }
}

@media (max-width: 767px) {

  .card-base {
    width: $card-width-mobile;
    right: 8%;
  }

  .card-bap {
    top: 50vh;
    right: 63vh;
  }

  .card-isave {
    top: 60vh;
  }

  .card-dosificacion {
    top: 70vh;
  }

  .card-water-feed {
    top: 80vh;
  }

  .card-rack-inlet {
    top: 40vh;
  }

  .card-rack-outlet {
    top: 45vh;
  }
}


/* @media (min-width: 1280px) and (max-width: 1919px) {

  // HD
  .card-base {
    width: 18vw; // Ajuste específico para HD
  }

  .card-bap {
    top: 73.9vh;
    right: 40vw;
  }

  .card-isave {
    top: 73.9vh;
    right: 25vw;
  }

  // Ajusta las demás tarjetas según sea necesario
} */

/* @media (min-width: 1920px) and (max-width: 2559px) {

  // Full HD
  .card-base {
    width: 15.5vw; // Ajuste específico para Full HD
  }

  .card-bap {
    top: 73.9vh;
    right: 54.8vw;
  }

  .card-isave {
    top: 73.9vh;
    right: 26.5vw;
  }

  // Ajusta las demás tarjetas según sea necesario
} */

@media (min-width: 2560px) and (max-width: 3839px) {

  // 2K
  .card-base {
    width: 14vw; // Ajuste específico para 2K
  }

  .card-bap {
    top: 73.9vh;
    right: 50vw;
  }

  .card-isave {
    top: 73.9vh;
    right: 28vw;
  }

  // Ajusta las demás tarjetas según sea necesario
}

@media (min-width: 3840px) {

  // 4K
  .card-base {
    width: 12vw; // Ajuste específico para 4K
  }

  .card-bap {
    top: 73.9vh;
    right: 48vw;
  }

  .card-isave {
    top: 73.9vh;
    right: 30vw;
  }


}

.synoptic-container {
  position: relative;
  width: 100%;
  height: 64vh;
}

.synoptic-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.horizontal-synoptic,
.vertical-synoptic {
  width: 100%;
  height: 100%;
}





.section {
  position: absolute;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    /* Móviles */
    width: 100px;
    height: 100px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Tablets */
    width: 120px;
    height: 120px;
  }
}

.section1 {
  top: 74%;
  left: -1.8%;
  /* Manteniendo para escritorio */

  @media (max-width: 767px) {
    top: 70%;
    left: 0%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    top: 72%;
    left: -3%;
  }
}

.section2 {
  top: 43%;
  right: 66.5%;
  /* Manteniendo para escritorio */

  @media (max-width: 767px) {
    top: 45%;
    right: 65%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    top: 36%;
    right: 65.5%;
  }
}

.section3 {
  top: 43%;
  right: 45%;

  @media (max-width: 767px) {
    top: 45%;
    right: 45%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    top: 36%;
    right: 45.5%;
  }
}

.section4 {
  top: 43%;
  right: 15.3%;

  @media (max-width: 767px) {
    top: 45%;
    right: 15%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    top: 41%;
    right: 15.3%;
  }
}

.status-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  z-index: 8;
}

.status-indicator {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 767px) {
    /* Móviles */
    gap: 5px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Tablets */
    gap: 3px;
    font-size: 0.5rem;
  }
}

.led {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: gray;
}

.led.Listo {
  background-color: gray;
}

.led.Marcha {
  background-color: gray;
}

.led.Fallo {
  background-color: gray;
}

.led.Alarma {
  background-color: gray;
}






.favorite-row {
  display: flex;
  align-items: center;




  @media (max-width: 767px) {
    font-size: 0.8rem;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 8px;
    height: 15px;
    padding: 4px;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }
}


.favorite-row .favorite-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.favorite-row .favorite-name {
  flex: 0 1 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.favorite-row .favorite-value {
  flex: 0 1 25%;
  text-align: right;
  min-width: 75px;
}

.favorite-value {
  font-size: 0.8rem;


  @media (max-width: 767px) {

    font-size: 0.8rem;

  }

  @media (min-width: 768px) and (max-width: 1023px) {

    font-size: 8px;

  }

  /*  @media (min-width: 1024px) {

    font-size: 1rem;

  } */

  @media (min-width: 1920px) {

    font-size: 0.9rem;

  }
}


.favorite-unit {
  font-size: 0.8rem;

  @media (max-width: 767px) {
    font-size: 0.6rem;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 9px;
  }
}